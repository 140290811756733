/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Fullmap, FullmapWrap, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"WoodArt Slovakia"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="css-1c0rbfb --style3 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39117/4cc7a746c5bd42ae9121416a111b6643_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1390}} anim={null} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":1425}} anim={"2"} animS={"5"}>
              
              <Title className="title-box fs--72 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1000}} content={"+421 905 310 613<br>&nbsp;info.woodartsk@gmail.com<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(230,230,230,1)"}} name={"1llr6zparvo"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Ako objednať?"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":650}} content={"<br>V kontaktnom formulári vyplníte -&nbsp;<span style=\"font-weight: bold;\">Meno</span>, <span style=\"font-weight: bold;\">e-mail</span>, <span style=\"font-weight: bold;\">Telefón.<br></span>V kolónke <span style=\"font-weight: bold;\">Typ/ID </span>si vyberiete produkt podľa <span style=\"font-weight: bold;\">ID</span> <span style=\"font-weight: bold;\">čísla</span>, ktoré nájdete pod každým produktom napr.: <span style=\"font-weight: bold;\">(ID:H1)</span>, zakliknete o čo máte záujem, doplníte text správy a odošlete<br><br>Po prijatí objednávky Vás budeme spätne kontaktovať<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(230,230,230,1)"}} name={"kontakt"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2" style={{"backgroundColor":"var(--color-blend--50)"}} anim={"2"}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">Nezáväzne kontaktovat´</span>"}>
              </Title>

              <ContactForm className="--shape2 ff--2 mt--30" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Meno a Priezvisko","type":"text","required":true,"placeholder":"Zadajte celé meno","id":"g9v9uq7qvvnm"},{"name":"Kontaktný e-mail","type":"email","required":true,"placeholder":"Zadajte Váš e-mail","id":"uharfnnq9wwc"},{"name":"Telefónne číslo","type":"text","placeholder":"Telefón +421","id":"letjlc6zjyrr","required":true},{"id":"bvsbhdlb3j9o","type":"select","name":"Typ/ID","placeholder":"Typ/ID","required":true,"options":[{"id":"kyczlxw200ch","value":"Sviečky PRÉMIUM"},{"id":"w3w8jtuitqow","value":"Hodiny"},{"id":"m1sm4h8ndhnb","value":"Padas"},{"id":"vvd974r5i0e3","value":"Podložky"},{"id":"6lq5o1vo3h8r","value":"Stolík"},{"id":"arx412kuot8j","value":"H1"},{"id":"irptxv7wd0d3","value":"S1"},{"id":"llb7gjehtw1u","value":"S2"},{"id":"cvm931l1m5vn","value":"S3"},{"id":"opx612jjan4v","value":"D1"},{"id":"ykh6lam8fw3i","value":"D2"},{"id":"ksbjuyt6pe5","value":"Vlastná požiadavka"}]},{"name":"Správa","type":"textarea","required":false,"placeholder":"Zadajte text správy","id":"7o3anjc2z8o6"},{"name":"Odoslať","type":"submit","id":"m3u253mckh0s"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"xyttat6wlfh"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"pata"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--left fs--26" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box text-box--left pt--08" style={{"marginTop":0}} content={"<a href=\"tel:+421 905 310 613\" style=\"color: var(--black);\">+421 905 310 613</a><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0}} content={"<span style=\"background-color: rgb(255, 255, 255);\"><a href=\"mailto:info.woodartsk@gmail.com\" style=\"color: var(--black);\">info.woodartsk@gmail.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":462}}>
              
              <Title className="title-box" style={{"marginBottom":0}} content={"Andrej Nagy<br>"}>
              </Title>

              <Image style={{"maxWidth":148,"marginTop":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png 860w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--right fs--26" content={"Návody a Starostlivosť<br>"}>
              </Title>

              <Text className="text-box text-box--right pt--08" style={{"marginTop":0}} content={"<a href=\"/navod-montaz\" style=\"background-color: rgb(255, 255, 255); color: var(--black);\">Zavesenie hodín</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":0}} content={"<a href=\"/starostlivost\" style=\"color: var(--black); background-color: rgb(255, 255, 255);\">Starostlivosť</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}